import { FC, ReactNode } from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import Link from 'next/link';

interface LinkButtonProps extends ButtonProps {
  children: ReactNode;
  href: string;
  target?: string;
  rel?: string;
}

const LinkButton: FC<LinkButtonProps> = ({ children, href, ...props }) => {
  // Test if external link open it in new tab
  const isNewTab = /(http(s?)):\/\//i.test(href) && { rel: 'noopener noreferrer', target: '_blank' };
  return (
    <Link href={href} passHref>
      <Button {...isNewTab} {...props}>
        {children}
      </Button>
    </Link>
  );
};
export default LinkButton;
