import { createTheme, SimplePaletteColorOptions, Theme } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
interface DarkWhiteThemeColors {
  darkColors: CustomColors;
  whiteColors: CustomColors;
}

interface GradientNames {
  lightDarkBlue?: string;
  lightDarkGreen?: string;
  lightDarkGreenReverse?: string;
}

interface CustomColors extends SimplePaletteColorOptions {
  // light, main, dark, contrastText
  // Add here new colors if you need
  green?: string;
  prompter?: string;
  simpleCard?: string;
  textGray?: string;
  mutedBg?: string;
  whiteGray?: string;
  reverseBg?: string;
  reverseTitle?: string;
  frameTitle?: string;
}
declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    switchedColors: CustomColors;
    gradients?: GradientNames;
  }
  interface PaletteOptions {
    switchedColors: CustomColors;
    gradients?: GradientNames;
  }
}

const DarkWhiteTheme: DarkWhiteThemeColors = {
  whiteColors: {
    main: '#338877',
    green: '#116666',
    prompter: '#F5F5F7',
    simpleCard: '#FFF',
    whiteGray: '#FFF',
    textGray: '#8E8F92',
    mutedBg: '#F5F5F7',
    reverseBg: '#32323D',
    reverseTitle: '#FFF',
    frameTitle: '#111133',
  },
  darkColors: {
    main: '#55AA88',
    green: '#55AA88',
    prompter: '#32323D',
    simpleCard: '#2A2A36',
    whiteGray: '#32323D',
    textGray: '#C6C6C6',
    mutedBg: '#2A2A36',
    reverseBg: '#F5F5F7',
    reverseTitle: '#111133',
    frameTitle: '#FFF',
  },
};

const allButtonStyles: CSSProperties = {
  boxShadow: 'none',
  borderRadius: '0 10px',
  padding: '14px 35px',
  textTransform: 'none',
  fontSize: 14,
  lineHeight: 1,
  fontWeight: 400,
  '@media(min-width: 375px)': {
    padding: '15px 40px',
  },
  '@media(min-width: 600px)': {
    padding: '15px 55px',
    fontSize: 18,
  },
  '&:hover': {
    boxShadow: 'none',
    transform: 'translateY(-5px)',
  },
};

const mainTheme = (isDark: boolean): Theme =>
  createTheme({
    spacing: 5,
    palette: {
      type: isDark ? 'dark' : 'light',
      switchedColors: DarkWhiteTheme[isDark ? 'darkColors' : 'whiteColors'],
      gradients: {
        lightDarkBlue: 'linear-gradient(212.37deg, #0065B4 0%, #37A2F6 141.92%)',
        lightDarkGreen: 'linear-gradient(213.58deg, #338877 3.97%, #55AA88 98.14%)',
        lightDarkGreenReverse: 'linear-gradient(132.66deg, #338877 1.51%, #55AA88 96.82%), #FFFFFF',
      },
      primary: {
        main: '#338877',
        dark: '#116666',
        600: '#0D5353',
        light: '#55AA88',
        contrastText: '#F4F4F4',
        700: '#1D1E20',
      },
      secondary: {
        main: '#C9C9C9',
        light: '#CFCFCF',
        dark: '#111133',
        contrastText: '#32323D',
      },
      text: {
        primary: isDark ? '#C6C6C6' : '#606060',
        secondary: '#929395',
      },
      common: {
        black: '#000',
        white: '#FFF',
      },
      info: {
        main: '#99C3BB',
        light: '#F5F5F7',
      },
      error: {
        main: '#F87588',
      },
      background: {
        default: isDark ? '#2A2A36' : '#FFF',
        paper: isDark ? '#32323D' : '#FFF',
      },
    },
    typography: {
      fontFamily: ['HelveticaNeueCyr', 'Helvetica Neue', 'sans-serif'].join(','),
      h1: {
        color: isDark ? '#FFF' : '#111133',
        fontWeight: 700,
        fontSize: 36,
        '@media(min-width: 600px)': {
          fontSize: 48,
        },
        '@media(min-width: 960px)': {
          fontSize: 64,
        },
      },
      h2: {
        color: isDark ? '#FFF' : '#111133',
        fontWeight: 700,
        fontSize: 32,
        '@media(min-width: 600px)': {
          fontSize: 42,
        },
        '@media(min-width: 960px)': {
          fontSize: 48,
        },
      },
      h3: {
        color: isDark ? '#FFF' : '#111133',
        fontWeight: 700,
        fontSize: 24,
        '@media(min-width: 600px)': {
          fontSize: 30, // ??? need to change
        },
        '@media(min-width: 960px)': {
          fontSize: 32,
        },
        '@media(min-width: 1280px)': {
          fontSize: 36,
        },
      },
      h4: {
        color: isDark ? '#FFF' : '#111133',
        fontWeight: 700,
        fontSize: 18,
        '@media(min-width: 600px)': {
          fontSize: 21,
        },
        '@media(min-width: 960px)': {
          fontSize: 24,
        },
      },
      h5: {
        color: isDark ? '#FFF' : '#111133',
        fontWeight: 700,
        fontSize: 20,
        '@media(min-width: 600px)': {
          fontSize: 24,
        },
      },
      subtitle1: {
        color: '#F4F4F4',
        fontWeight: 400,
        fontSize: 18,
        '@media(min-width: 600px)': {
          fontSize: 24,
        },
      },
      subtitle2: {
        fontWeight: 400,
        fontSize: 16,
      },
      body1: {
        fontWeight: 400,
        fontSize: 16,
        '@media(min-width: 600px)': {
          fontSize: 20,
        },
      },
      body2: {
        fontWeight: 400,
        fontSize: 16,
        '@media(min-width: 600px)': {
          fontSize: 18,
        },
      },
    },
    overrides: {
      MuiContainer: {
        fixed: {
          '@media(min-width: 1920px)': {
            maxWidth: 1280,
          },
        },
      },
      MuiIconButton: {
        colorPrimary: {
          backgroundColor: isDark ? 'rgba(85, 170, 136, 0.2)' : 'rgba(51, 136, 119, 0.2)',
          borderRadius: '0px 5px',
          transition: 'all 300ms',
          '&:hover': {
            backgroundColor: '#338877',
            boxShadow: '0px 4px 17px rgba(51, 136, 119, 0.5)',
          },
        },
      },
      MuiButton: {
        outlined: allButtonStyles,
        contained: allButtonStyles,
        containedPrimary: {
          color: 'white',
          background: 'linear-gradient(94.14deg, #55AA88 3.76%, #116666 100%)',
          transition: 'all 200ms',
          boxShadow: '0px 4px 22px rgba(51, 136, 119, 0.5)',
          '&:hover': {
            background: 'linear-gradient(94.14deg, #55AA88 3.76%, #116666 100%)',
            boxShadow: '0px 14px 22px rgba(51, 136, 119, 0.5)',
          },
          '&$disabled': {
            background: '#99C3BB',
            boxShadow: 'none',
          },
        },
        outlinedPrimary: {
          border: isDark ? '2px solid #55AA88' : '2px solid #338877',
          transition: 'all 200ms',
          padding: '13px 35px',
          color: isDark ? '#55AA88' : '#338877',
          '&:hover': {
            border: isDark ? '2px solid #55AA88' : '2px solid #338877',
            boxShadow: '0px 14px 22px rgba(51, 136, 119, 0.5)',
            backgroundColor: isDark ? '#55AA88' : '#338877',
            color: 'white',
          },
        },
      },
      MuiCard: {
        root: {
          color: 'white',
          background: '#0D5353',
          borderRadius: '0px 20px',
          transition: 'all 200ms',
          '&:hover': {
            background: 'linear-gradient(254.63deg, #55AA88 2.13%, #338877 90.39%)',
          },
        },
      },
      MuiDivider: {
        root: {
          backgroundColor: '#CFCFCF',
        },
      },
      MuiAppBar: {
        root: {
          boxShadow: 'none',
          borderBottom: isDark ? 'none' : '1px solid rgba(146, 147, 149, 0.2)',
        },
      },
      MuiPopover: {
        root: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'rgba(0, 0, 0, 0.7)',
        },
      },
    },
  });
export default mainTheme;
