import { ThemeProvider, useMediaQuery } from '@material-ui/core';
import mainTheme from 'config/theme/main-theme';
import { createContext, FC, ReactNode, useMemo, useState } from 'react';

interface IThemeTypeProviderProps {
  children: ReactNode;
}

export type TUseDarkMode = {
  isDarkMode: boolean;
  handleChangeMode: (mode: TMode) => void;
};

export const ThemeMode = createContext<TUseDarkMode>({
  isDarkMode: false,
  handleChangeMode: (mode: TMode) => {
    console.log(mode);
  },
});
export type TMode = 'dark' | 'light';

const ThemeTypeProvider: FC<IThemeTypeProviderProps> = ({ children }) => {
  const isDarkPrefer = useMediaQuery('(prefers-color-scheme: dark)');
  const [isDarkMode, setIsDarkMode] = useState<boolean>(false);

  useMemo(() => {
    setIsDarkMode(isDarkPrefer);

    if (typeof window !== 'undefined') {
      const preferTheme = localStorage.getItem('theme');

      if (preferTheme) {
        setIsDarkMode(preferTheme === 'dark');
      }
    }
  }, [isDarkPrefer]);

  const handleChangeMode = (mode: TMode): void => {
    localStorage.setItem('theme', mode === 'dark' ? 'dark' : 'light');
    setIsDarkMode(mode === 'dark');
  };

  return (
    <ThemeMode.Provider value={{ isDarkMode, handleChangeMode }}>
      <ThemeProvider theme={mainTheme(isDarkMode)}>{children}</ThemeProvider>
    </ThemeMode.Provider>
  );
};

export default ThemeTypeProvider;
