import { isPhoneInvalid } from 'utils/phoneHelpers';
import isEmail from 'validator/lib/isEmail';
import isEmpty from 'validator/lib/isEmpty';

export type TQuoteValue = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  message?: string;
};

export interface IContactUsRequest extends TQuoteValue {
  [key: string]: string;
}

type TQuoteValidator = Partial<TQuoteValue>;

const quoteValidator = ({ email = '', firstName = '', lastName = '', phone = '' }: TQuoteValidator): TQuoteValidator => {
  const errors: TQuoteValidator = {};

  // Email
  if (!isEmail(email)) errors.email = 'Invalid Email address';
  if (isEmpty(email)) errors.email = 'Required field';

  // First Name
  if (isEmpty(firstName)) errors.firstName = 'Required field';

  // Last Name
  if (isEmpty(lastName)) errors.lastName = 'Required field';

  // Phone
  if (isPhoneInvalid(phone)) errors.phone = 'Invalid phone number';
  if (isEmpty(phone)) errors.phone = 'Required field';

  return errors;
};

export default quoteValidator;
