import React, { CSSProperties, FC, ReactNode } from 'react';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { Variant } from '@material-ui/core/styles/createTypography';
import clsx from 'clsx';
import { LinkButton, UpperTitle } from 'components/atoms';
import { UpperTitleProps } from 'components/atoms/UpperTitle';

export interface ContentDescriptionProps {
  upperTitle?: UpperTitleProps;
  iconTitle?: ReactNode;
  title: { text: string | ReactNode; variant?: 'h1' | 'h2'; className?: string; style?: CSSProperties };
  text?: { text: string | ReactNode; className?: string; variant?: Variant };
  link?: { text: string; href: string; className?: string };
}

const useStyles = makeStyles((theme: Theme) => ({
  upperTitle: {
    marginBottom: theme.spacing(3),
  },
  titleWrapper: {
    display: 'flex',
  },
  title: {
    marginBottom: theme.spacing(3),
    whiteSpace: 'pre-line',
  },
  text: {
    whiteSpace: 'pre-line',
  },
  button: {
    marginTop: theme.spacing(8),
  },
}));

const ContentDescription: FC<ContentDescriptionProps> = ({ iconTitle, title, upperTitle, text, link, children }) => {
  const classes = useStyles();

  return (
    <>
      {upperTitle && (
        <UpperTitle
          text={upperTitle.text}
          className={clsx(classes.upperTitle, upperTitle.className)}
          upperTitleTextClass={clsx(upperTitle.upperTitleTextClass)}
          colorSquare={upperTitle.colorSquare}
        />
      )}
      <Typography variant={title.variant || 'h2'} className={clsx(classes.title, title.className)} style={title.style}>
        {iconTitle && iconTitle}
        {title.text}
      </Typography>
      {children}
      {text && (
        <Typography variant={text.variant || 'body1'} className={clsx(classes.text, text.className)}>
          {text.text}
        </Typography>
      )}
      {link && (
        <LinkButton href={link.href} color="primary" variant="contained" className={clsx(classes.button, link.className)}>
          {link.text}
        </LinkButton>
      )}
    </>
  );
};

export default ContentDescription;
