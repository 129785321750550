import { ChangeEvent, FC } from 'react';
import { Box, makeStyles, StandardTextFieldProps, TextField, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';

export interface InputFieldProps extends StandardTextFieldProps {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  value: string;
  error?: boolean;
  light?: boolean;
  maxLength?: number;
}

type StyleProps = Partial<InputFieldProps>;

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    color: theme.palette.secondary.light,
    fontSize: 18,
  },
  shrink: {
    color: ({ light }: StyleProps) => `${light ? theme.palette.common.black : theme.palette.secondary.light} !important`,
  },
  errorShrink: {
    color: `${theme.palette.error.main} !important`,
  },
  underline: {
    '&:after': {
      borderBottom: ({ light }: StyleProps) => `2px solid ${theme.palette.common[light ? 'black' : 'white']}`,
    },
    '&:before': {
      borderBottom: `1px solid ${theme.palette.secondary.light}`,
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.secondary.light}`,
    },
  },
  underlineError: {
    '&:after': {
      borderBottom: `2px solid ${theme.palette.error.main}`,
    },
  },
  helperText: {
    fontSize: 14,
    color: ({ error }: StyleProps) => (error ? theme.palette.error.main : theme.palette.secondary.light),
    fontWeight: 500,
  },
  input: {
    color: ({ light }: StyleProps) => theme.palette.common[light ? 'black' : 'white'],
    fontSize: 18,
  },
  counter: {
    position: 'absolute',
    bottom: theme.spacing(-1),
    right: 0,
    color: theme.palette.secondary.light,
  },
}));

const InputField: FC<InputFieldProps> = ({ error, maxLength, value, light = false, ...props }) => {
  const classes = useStyles({ light, error });

  return (
    <Box position="relative">
      <TextField
        {...props}
        fullWidth
        value={value}
        error={error}
        inputProps={{ className: classes.input, maxLength }}
        FormHelperTextProps={{ className: classes.helperText }}
        InputProps={{ classes: { underline: clsx(error ? classes.underlineError : classes.underline) } }}
        InputLabelProps={{
          className: classes.label,
          classes: { focused: clsx(error ? classes.errorShrink : classes.shrink) },
        }}
      />
      {maxLength && (
        <Typography variant="subtitle2" className={classes.counter}>
          {value.length} / {maxLength}
        </Typography>
      )}
    </Box>
  );
};

export default InputField;
