import { useScrollTrigger } from '@material-ui/core';
import { useEffect, useState } from 'react';

type TValues = [number, number];
type IUseScrollGradientCounter = (values: TValues) => TValues;

const useScrollGradientCounter: IUseScrollGradientCounter = (initialValues) => {
  const [counter, setCounter] = useState<TValues>(initialValues);
  const scrollDown = useScrollTrigger();

  function handleScroll(): void {
    const incValue = (num: number) => (num <= 100 ? num + 1 : 0);
    const decValue = (num: number) => (num <= 1 ? 100 : num - 1);
    const detectScrollDestination = (num: number) => (scrollDown ? incValue(num) : decValue(num));

    setCounter((prevValues) => [detectScrollDestination(prevValues[0]), detectScrollDestination(prevValues[1])]);
  }

  useEffect(() => {
    window.removeEventListener('scroll', handleScroll);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [scrollDown]);

  return counter;
};

export default useScrollGradientCounter;
