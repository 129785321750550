import { Box, Button, Container, Grid, Hidden, makeStyles, Theme, Typography } from '@material-ui/core';
import { useDarkMode } from 'hooks';
import Link from 'next/link';
import React, { FC } from 'react';

interface HeroSectionProps {
  bgImage: { dark: string; light: string };
  phoneImage: { x1: string; x2: string };
  title: string;
  description: string;
}

interface StyleProps extends Partial<HeroSectionProps> {
  isDarkMode: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    backgroundImage: ({ bgImage, isDarkMode }: StyleProps) => `url(${isDarkMode ? bgImage.dark : bgImage.light})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPositionX: '20%',
    backgroundPositionY: '150%',
  },
  container: {
    paddingTop: theme.spacing(28),
    minHeight: '80vh',
  },
  whiteText: {
    color: theme.palette.common.white,
  },
  phoneBg: {
    maxWidth: '100%',
    width: '100%',
    marginTop: theme.spacing(28),
    [theme.breakpoints.up('md')]: {
      width: 'auto',
      marginTop: 0,
    },
  },
}));

const HeroSection: FC<HeroSectionProps> = ({ bgImage, description, title, phoneImage }) => {
  const { isDarkMode } = useDarkMode();
  const classes = useStyles({ bgImage, isDarkMode });
  return (
    <Box className={classes.wrapper}>
      <Container fixed>
        <Grid container className={classes.container}>
          <Hidden smDown>
            <Grid md={2} item />
          </Hidden>
          <Grid md={5} xs={12} item>
            <Typography className={classes.whiteText} variant="h1">
              {title}
            </Typography>
            <Box mt={2} mb={8}>
              <Typography className={classes.whiteText} variant="body2">
                {description}
              </Typography>
            </Box>
            <Link href="/contact-us" passHref>
              <Button variant="contained" color="primary">
                Contact Us
              </Button>
            </Link>
          </Grid>
          <Grid xs={2} item />
          <Grid item md={3} xs={8}>
            <img
              className={classes.phoneBg}
              src={phoneImage.x1}
              srcSet={`${phoneImage.x1} 516w, ${phoneImage.x2} 1084w`}
              alt="phone"
            />
          </Grid>
          <Hidden xsUp>
            <Grid xs={2} item />
          </Hidden>
        </Grid>
      </Container>
    </Box>
  );
};

export default HeroSection;
