import { Box, Button, ButtonProps, Grid, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import InputField, { InputFieldProps } from 'components/atoms/InputField';
import { quoteValidator, subscribeValidator } from 'data/validators';
import { useDarkMode, useMediaUp } from 'hooks';
import useFormSender, { TSimpleObject } from 'hooks/useFormSender';
import React, { FC, ReactNode } from 'react';
import { Field, Form } from 'react-final-form';
import { parsePhoneOnType } from 'utils/phoneHelpers';

interface QuoteFormProps {
  fields?: IFields[];
  externalFields?: ReactNode;
  externalValues?: TSimpleObject;
  light?: boolean;
  buttonText?: string;
  buttonProps?: ButtonProps;
  externalFieldsButtonInline?: boolean;
  apiPath?: string;
  useSubscribeValidator?: boolean;
  useGridHalfFields?: boolean;
}

export interface IFields {
  name: string;
  label: string;
  additionalInputParams?: Partial<InputFieldProps>;
  additionalFieldParams?: unknown;
}

const defaultFields: IFields[] = [
  {
    label: 'First Name*',
    name: 'firstName',
  },
  {
    label: 'Last Name*',
    name: 'lastName',
  },
  {
    label: 'Phone*',
    name: 'phone',
    additionalFieldParams: {
      parse: parsePhoneOnType,
    },
  },
  {
    label: 'Email*',
    name: 'email',
  },
  {
    label: 'Message',
    name: 'message',
    additionalInputParams: {
      style: { marginTop: 20 },
      multiline: true,
      maxLength: 200,
    },
  },
];

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    marginBottom: theme.spacing(6),
  },
  submitButton: {
    background: theme.palette.primary.light,
    marginTop: theme.spacing(2),
    '&:hover': {
      background: theme.palette.primary.light,
    },
  },
  addMargin: { marginTop: theme.spacing(4) },
  externalFieldsButtonInline: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
    },
  },
}));

const QuoteForm: FC<QuoteFormProps> = ({
  fields = defaultFields,
  externalFields,
  buttonProps,
  buttonText = 'Send',
  externalValues,
  apiPath = '/api/contact-form',
  light = false,
  externalFieldsButtonInline = false,
  useSubscribeValidator = false,
  useGridHalfFields = false,
}) => {
  const classes = useStyles();
  const { onSubmit } = useFormSender(apiPath);
  const { isDarkMode } = useDarkMode();
  const isLight = isDarkMode ? false : light;
  const isSmallUp = useMediaUp('sm');

  return (
    <Form
      validate={useSubscribeValidator ? subscribeValidator : quoteValidator}
      onSubmit={(...formData) => onSubmit(...formData, externalValues)}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={useGridHalfFields && isSmallUp ? 6 : 0}>
            {fields.map(({ label, name, additionalInputParams, additionalFieldParams }, index) => (
              <Grid item md={useGridHalfFields ? 6 : 12} xs={12} key={index + name}>
                <Field name={name} {...additionalFieldParams}>
                  {({ input, meta: { error, touched } }) => (
                    <InputField
                      light={isLight}
                      {...input}
                      {...additionalInputParams}
                      disabled={submitting}
                      label={label}
                      className={clsx(classes.input, additionalInputParams?.className)}
                      helperText={touched && error}
                      error={touched && !!error}
                    />
                  )}
                </Field>
              </Grid>
            ))}
          </Grid>
          <Box className={clsx(externalFieldsButtonInline && classes.externalFieldsButtonInline)}>
            {externalFields}
            <Button
              {...buttonProps}
              disabled={submitting}
              type="submit"
              variant="contained"
              color="primary"
              className={buttonProps?.className || classes.submitButton}
            >
              {buttonText}
            </Button>
          </Box>
        </form>
      )}
    />
  );
};

export default QuoteForm;
