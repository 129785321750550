import React, { FC } from 'react';
import { Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { MainIcon5 } from 'svgs';
import { createStyles, makeStyles } from '@material-ui/styles';

interface UpperSubTitleProps {
  className?: string;
  text: string;
  spacing?: boolean;
}

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    subTitle: {
      lineHeight: 1.3,
      color: theme.palette.primary.contrastText,
    },
    subTitleIcon: {
      marginLeft: theme.spacing(1),
    },
    subTitleSpacing: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(0),
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(7.5),
      },
    },
  });
});

const UpperSubTitle: FC<UpperSubTitleProps> = ({ className, spacing, text }) => {
  const classes = useStyles();

  return (
    <Typography variant="subtitle1" className={clsx(classes.subTitle, !!spacing && classes.subTitleSpacing, className)}>
      {text} <MainIcon5 className={classes.subTitleIcon} />
    </Typography>
  );
};
export default UpperSubTitle;
