const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const displayPublishAt = (value: string) => {
  const date = new Date(value);
  return `${date.getDate()} ${months[date.getMonth()]}`;
};

export const truncate = (text: string, count: number) => {
  if (text.length <= count) {
    return text;
  }
  const subString = text.substr(0, count - 1);
  return subString.substr(0, subString.lastIndexOf(' ')) + ' ...';
};
