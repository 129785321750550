import { FormControlLabel, makeStyles, Radio, RadioGroup, Theme, Typography } from '@material-ui/core';
import React, { ChangeEvent, FC } from 'react';
import clsx from 'clsx';

interface CheckmarkGroupProps {
  title?: string;
  onChange: (value: string) => void;
  value: string;
  values: string[];
  className?: string;
  lightTheme?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(2),
  },
  radioGroup: {
    flexDirection: 'row',
  },
  radioButton: {
    '& .MuiFormControlLabel-label': {
      color: ({ lightTheme }: Partial<CheckmarkGroupProps>) =>
        lightTheme ? theme.palette.common.white : theme.palette.text.primary,
    },
  },
  radio: {
    backgroundColor: 'transparent',
    '&.Mui-checked': {
      '& svg': {
        fill: ({ lightTheme }: Partial<CheckmarkGroupProps>) => (lightTheme ? theme.palette.primary.light : 'currentColor'),
        '&:not(:last-child)': {
          fill: ({ lightTheme }: Partial<CheckmarkGroupProps>) => (lightTheme ? theme.palette.info.light : 'currentColor'),
        },
      },
    },
    '&:hover': {
      backgroundColor: 'transparent !important',
      boxShadow: 'none',
    },
  },
}));

const RadioGroupComponent: FC<CheckmarkGroupProps> = ({ lightTheme = false, onChange, values, className, title, value }) => {
  const classes = useStyles({ lightTheme });

  const handleChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    onChange(value);
  };

  return (
    <>
      {title && (
        <Typography variant="body2" className={classes.title}>
          {title}
        </Typography>
      )}
      <RadioGroup
        aria-label={`radio-${title}`}
        value={value}
        onChange={handleChange}
        className={clsx(classes.radioGroup, className)}
      >
        {values.map((item, index) => (
          <FormControlLabel
            key={item + index}
            value={item}
            control={<Radio size="small" color="primary" className={classes.radio} />}
            label={<Typography variant="body2">{item}</Typography>}
            className={classes.radioButton}
          />
        ))}
      </RadioGroup>
    </>
  );
};

export default RadioGroupComponent;
