import React, { FC, ReactNode } from 'react';
import { Box, Container, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { MultiLinesIcon, PlusIcon, RedCirclePointedIcon } from 'svgs';
import clsx from 'clsx';
import { Variant } from '@material-ui/core/styles/createTypography';
import { LinkButton } from 'components/atoms';

interface ContentDescriptionProps {
  title: { text: string; variant?: Variant; className?: string };
  description?: { text: string; className?: string };
  customIcons?: ReactNode;
  className?: string;
  innerContainerClassName?: string;
  link?: { text: string; href: string };
  withoutMultiLinesIcon?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(14, 0),
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
    margin: theme.spacing(10, 0),
    position: 'relative',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(15, 0),
    },
  },
  multiLinesIcon: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      position: 'absolute',
      left: theme.spacing(-8),
      bottom: theme.spacing(7.4),
      transform: 'rotateZ(90deg)',
      maxWidth: '100%',
      height: theme.spacing(8),
      width: theme.spacing(42),
      '& path': {
        stroke: theme.palette.primary.light,
      },
    },
  },
  plusIcon: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      position: 'absolute',
    },
  },
  plusIcon1: {
    top: theme.spacing(26),
    left: theme.spacing(12),
    [theme.breakpoints.up('lg')]: {
      left: theme.spacing(65),
    },
  },
  plusIcon2: {
    bottom: theme.spacing(3),
    right: theme.spacing(12),
    [theme.breakpoints.up('lg')]: {
      right: theme.spacing(65),
    },
  },
  redCirclePointedIcon: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      position: 'absolute',
      top: theme.spacing(13),
      right: theme.spacing(-4),
    },
  },
  titleBlock: {
    textAlign: 'center',
  },
  title: {
    color: theme.palette.common.white,
  },
  iconBlock: {
    marginTop: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    height: 25,
    maxWidth: 148,
  },
  text: {
    marginTop: theme.spacing(10),
    textAlign: 'center',
  },
  link: {
    marginTop: theme.spacing(7),
    margin: '0 auto',
  },
}));
const FullSizeContentDescription: FC<ContentDescriptionProps> = ({
  title,
  description,
  customIcons,
  className,
  link,
  withoutMultiLinesIcon = false,
  innerContainerClassName = '',
}) => {
  const classes = useStyles();
  return (
    <Box className={clsx(classes.root, className)}>
      {customIcons || (
        <>
          <MultiLinesIcon className={classes.multiLinesIcon} viewBox="0 0 59 11" />
          <PlusIcon className={clsx(classes.plusIcon1, classes.plusIcon)} />
          <PlusIcon className={clsx(classes.plusIcon2, classes.plusIcon)} />
          <RedCirclePointedIcon className={classes.redCirclePointedIcon} />
        </>
      )}
      <Container maxWidth={false} fixed>
        <Grid justifyContent="center" container className={innerContainerClassName}>
          <Grid className={classes.titleBlock} item>
            <Typography className={clsx(classes.title, title.className)} variant={title.variant || 'h3'}>
              {title.text}
            </Typography>
          </Grid>
          {!withoutMultiLinesIcon && (
            <Grid xs={12} className={classes.iconBlock} item>
              <MultiLinesIcon className={classes.icon} width="100%" height="100%" viewBox="0 0 59 11" />
            </Grid>
          )}
          <Grid xs={12} lg={10} item>
            {description && <Typography className={clsx(classes.text, description.className)}>{description.text}</Typography>}
          </Grid>
          {link && (
            <LinkButton href={link.href} color="primary" variant="contained" className={classes.link}>
              {link.text}
            </LinkButton>
          )}
        </Grid>
      </Container>
    </Box>
  );
};

export default FullSizeContentDescription;
