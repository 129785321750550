import { FC, ReactNode, useEffect, useRef } from 'react';
import { Interpolation, useSpring } from 'react-spring';

interface IUseParallax {
  transform: (scope: number) => Interpolation<number, string>;
  ParallaxProvider: FC;
}

const useParallax = (axis: 'X' | 'Y' = 'Y'): IUseParallax => {
  const ref = useRef(null);
  const [{ offset }] = useSpring(() => ({ offset: 0 }));

  const handleScroll = () => {
    if (ref?.current) {
      const posY = ref.current.getBoundingClientRect().top;
      const newOffset = window.pageYOffset - posY;
      offset.start(newOffset);
      return;
    }

    console.error('Please add the <ParallaxProvider /> to your component');
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const transformY = (scope: number) => offset.to((o) => `translate3d(0, ${(o * scope) / 2.5}px, 0)`);
  const transformX = (scope: number) => offset.to((o) => `translate3d(${(o * scope) / 2.5}px, 0, 0)`);
  const ParallaxProvider: FC<{ children: ReactNode }> = ({ children }) => <div ref={ref}>{children}</div>;

  return { transform: axis === 'Y' ? transformY : transformX, ParallaxProvider };
};
export default useParallax;
