import React, { FC, ReactNode } from 'react';
import { Container, Grid, GridSize, makeStyles, Theme } from '@material-ui/core';
import { ContentDescription } from 'components/structures/general';

interface HeroProductSectionProps {
  svgIcon?: ReactNode;
  title: string;
  description: string;
  image: { image: string; maxWidth?: number };
  hypertextReference: string;
  gridLgContent?: GridSize;
  gridLgImage?: GridSize;
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: theme.spacing(4, 0, 15),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(10),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(18, 0, 20),
    },
  },
  title: {
    paddingTop: 0,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    '& svg': {
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      '& svg': {
        marginBottom: 0,
        marginRight: theme.spacing(2),
      },
      paddingTop: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      '& svg': {
        marginRight: theme.spacing(6),
      },
    },
  },
  text: {
    paddingTop: theme.spacing(4),
    color: theme.palette.switchedColors.textGray,
    textAlign: 'center',
  },
  itemBlock: {
    justifyContent: 'center',
    display: 'flex',
  },
  button: {
    backgroundColor: theme.palette.primary.light,
    marginBottom: theme.spacing(16),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(11),
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  image: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      maxWidth: ({ image: { maxWidth } }: Partial<HeroProductSectionProps>) => maxWidth && maxWidth,
    },
  },
}));

const HeroProductSection: FC<HeroProductSectionProps> = (props) => {
  const { image, title, hypertextReference, svgIcon, description, gridLgImage = 12, gridLgContent = 10 } = props;
  const classes = useStyles({ image });

  return (
    <Container fixed>
      <Grid container className={classes.wrapper} justifyContent="center">
        <Grid item xs={12} lg={gridLgContent} style={{ textAlign: 'center' }}>
          <ContentDescription
            iconTitle={svgIcon && svgIcon}
            title={{ text: title, className: classes.title, variant: 'h1' }}
            text={{ text: description, variant: 'body2', className: classes.text }}
            link={{ text: 'Give it a go', href: hypertextReference, className: classes.button }}
          />
        </Grid>
        <Grid item xs={12} lg={gridLgImage} className={classes.itemBlock}>
          <img src={image.image} alt={image.image} className={classes.image} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default HeroProductSection;
