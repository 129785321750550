import isEmail from 'validator/lib/isEmail';
import isEmpty from 'validator/lib/isEmpty';

export type TSubscribeValue = {
  fullName: string;
  email: string;
};

export interface ISubscribeRequest extends TSubscribeValue {
  [key: string]: string;
}

type TSubscribeValidator = Partial<TSubscribeValue>;

const subscribeValidator = ({ email = '', fullName = '' }: TSubscribeValidator): TSubscribeValidator => {
  const errors: TSubscribeValidator = {};

  // Email
  if (!isEmail(email)) errors.email = 'Invalid Email address';
  if (isEmpty(email)) errors.email = 'Required field';

  // Full Name
  if (isEmpty(fullName)) errors.fullName = 'Required field';

  return errors;
};

export default subscribeValidator;
