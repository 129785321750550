import { NormalizedCacheObject } from '@apollo/client';
import { DocumentNode } from 'graphql';
import { initializeApollo } from 'lib/apollo-client';

type ApolloFetchProps = {
  props: {
    initialApolloState: NormalizedCacheObject | null;
  };
  revalidate?: number;
};

export type ApolloFetch = Promise<ApolloFetchProps>;

const useApolloFetch = async (query: DocumentNode): Promise<ApolloFetchProps> => {
  try {
    const apolloClient = initializeApollo();
    await apolloClient.query({ query });

    return {
      props: {
        initialApolloState: apolloClient.cache.extract(),
      },
    };
  } catch (err) {
    return {
      props: {
        initialApolloState: null,
      },
    };
  }
};

export default useApolloFetch;
