import { alpha, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React, { FC } from 'react';

export interface SquareIconComponentProps {
  className?: string;
  colorSquare?: string;
}

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    square: {
      width: 53,
      height: 53,
      borderRadius: 5,
      backgroundColor: ({ colorSquare }: Partial<SquareIconComponentProps>) =>
        alpha(colorSquare || theme.palette.switchedColors.main, 0.1),
      [theme.breakpoints.up('md')]: {
        width: 93,
        height: 93,
      },
    },
  });
});

const SquareIconComponent: FC<SquareIconComponentProps> = ({ className, colorSquare }) => {
  const classes = useStyles({ colorSquare });

  return <div className={clsx(classes.square, className)} />;
};
export default SquareIconComponent;
