import { FC, forwardRef, ReactNode, Ref } from 'react';
import Carousel, { ResponsiveType } from 'react-multi-carousel';

interface CarouselComponentProps {
  children: ReactNode;
  className?: string;
  slidesToSlide?: number;
  responsive?: ResponsiveType;
  swipeable?: boolean;
  beforeChange?: () => void;
  afterChange?: () => void;
  ref?: Ref<Carousel>;
}

const CarouselComponent: FC<CarouselComponentProps> = forwardRef(
  ({ children, responsive, className, slidesToSlide = 1, swipeable = false, beforeChange, afterChange }, ref) => {
    const defaultResponsive: ResponsiveType = {
      mobile: {
        breakpoint: {
          max: 960,
          min: 0,
        },
        items: 1,
      },
      tablet: {
        breakpoint: {
          max: 5000,
          min: 960,
        },
        items: 2,
      },
    };

    return (
      <Carousel
        ssr
        ref={ref}
        className={className}
        responsive={{ ...defaultResponsive, ...responsive }}
        beforeChange={beforeChange}
        afterChange={afterChange}
        additionalTransfrom={0}
        minimumTouchDrag={80}
        arrows={false}
        swipeable={swipeable}
        draggable={false}
        keyBoardControl={false}
        slidesToSlide={slidesToSlide}
        infinite
      >
        {children}
      </Carousel>
    );
  },
);

export default CarouselComponent;
