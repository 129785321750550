import { SvgIconProps } from '@material-ui/core';
import { FC, forwardRef } from 'react';
import { ReactComponent as FbIconSvg } from './fb.svg';
import { ReactComponent as MultiLinesSvg } from './multiLines.svg';
import { ReactComponent as USSLogoSvg } from './uss-logo.svg';
import { ReactComponent as InstagramSvg } from './instagram.svg';
import { ReactComponent as FacebookSvg } from './fb.svg';
import { ReactComponent as LinkedInSvg } from './linked-in.svg';
import { ReactComponent as RedCirclePointedSvg } from './red-circle-pointed.svg';
import { ReactComponent as MainIcon1Svg } from './main-icon-1.svg';
import { ReactComponent as MainIcon2Svg } from './main-icon-2.svg';
import { ReactComponent as MainIcon3Svg } from './main-icon-3.svg';
import { ReactComponent as MainIcon4Svg } from './main-icon-4.svg';
import { ReactComponent as MainIcon5Svg } from 'svgs/main-icon-5.svg';
import { ReactComponent as ArrowRightSvg } from './arrow-right.svg';
import { ReactComponent as LightModeSvg } from './light-mode-icon.svg';
import { ReactComponent as DarkModeSvg } from './dark-mode-icon.svg';
import { ReactComponent as HandshakeSvg } from './handshake.svg';
import { ReactComponent as ImprovementSvg } from './improvement.svg';
import { ReactComponent as ListSvg } from './list.svg';
import { ReactComponent as TeamSvg } from './team.svg';
import { ReactComponent as ArrowSvg } from './arrow.svg';
import { ReactComponent as VideoIconSvg } from './video-icon.svg';
import { ReactComponent as VideoIcon2Svg } from './video-icon-2.svg';
import { ReactComponent as IndustriesAirSvg } from './industries-air.svg';
import { ReactComponent as IndustriesLampSvg } from './industries-lamp.svg';
import { ReactComponent as IndustriesPipeSvg } from './industries-pipe.svg';
import { ReactComponent as IndustriesTvSvg } from './industries-tv.svg';
import { ReactComponent as ServicesIcon1Svg } from './services-icon-1.svg';
import { ReactComponent as ServicesIcon2Svg } from './services-icon-2.svg';
import { ReactComponent as ServicesIcon3Svg } from './services-icon-3.svg';
import { ReactComponent as ServicesIcon4Svg } from './services-icon-4.svg';
import { ReactComponent as ServicesIcon5Svg } from './services-icon-5.svg';
import { ReactComponent as ArrowOfferCardSvg } from './arrow-offer-card.svg';
import { ReactComponent as PlusSvg } from './plus.svg';
import { ReactComponent as BespokeServerSvg } from './bespoke-server.svg';
import { ReactComponent as BespokeGearSvg } from './bespoke-gear.svg';
import { ReactComponent as BespokeLampSvg } from './bespoke-lamp.svg';
import { ReactComponent as ErrorPageSvg } from './404.svg';
import { ReactComponent as ErrorServerSvg } from './500.svg';
import { ReactComponent as SearchIconSvg } from './search-icon.svg';
import { ReactComponent as WebDesignIconSvg } from './web-design-icon.svg';
import { ReactComponent as WebProgrammingIconSvg } from './web-programming-icon.svg';
import { ReactComponent as ChecklistIconSvg } from './checklist-icon.svg';
import { ReactComponent as ArrowDownSvg } from './arrow-down.svg';
import { ReactComponent as ArrowDownWhiteSvg } from './arrow-down-white.svg';
import { ReactComponent as BlackDotsSvg } from './black-dots.svg';
import { ReactComponent as WhitePlusSvg } from './white-plus.svg';
import { ReactComponent as EmailMapSvg } from './email-map.svg';
import { ReactComponent as MarkerMapSvg } from './marker-map.svg';
import { ReactComponent as PhoneMapSvg } from './phone-map.svg';
import { ReactComponent as CheckCircleSvg } from './checkCircle.svg';
import { ReactComponent as LockIconSvg } from './lock.svg';
import { ReactComponent as ZeroPercentSvg } from './zero-percent.svg';
import { ReactComponent as CircleIconSvg } from './circle-icon.svg';
import { ReactComponent as USSLogoWhiteSVG } from './logo-white.svg';
import { ReactComponent as FlowerSVG } from './flower.svg';
import { ReactComponent as UtilitiesOneLogoSVG } from './utilities-one-logo.svg';
import { ReactComponent as EngineeringIconSVG } from './engineering.svg';
import { ReactComponent as EnergyIconSvg } from './energyIcon.svg';
import { ReactComponent as AreasWeServeIcon1SVG } from './areasWeServeIcon1.svg';
import { ReactComponent as AreasWeServeIcon2SVG } from './areasWeServeIcon2.svg';
import { ReactComponent as AreasWeServeIcon3SVG } from './areasWeServeIcon3.svg';
import { ReactComponent as AreasWeServeIcon4SVG } from './areasWeServeIcon4.svg';
import { ReactComponent as AreasWeServeIcon5SVG } from './areasWeServeIcon5.svg';
import { ReactComponent as ServerIconSvg } from './server.svg';
import { ReactComponent as ConstructionIconSVG } from './construction.svg';
import { ReactComponent as PatronTruckingLogoSVG } from 'svgs/patron-trucking-logo.svg';
import { ReactComponent as TruckloadSVG } from './truckload.svg';
import { ReactComponent as LessThanTruckloadSVG } from './less-than-truckload.svg';
import { ReactComponent as IntermodalSVG } from './intermodal.svg';
import { ReactComponent as MultiDotsSVG } from './multi-dots.svg';
import { ReactComponent as SettingsSVG } from './settings.svg';
import { ReactComponent as EngineeringBgIconSVG } from './engineering-bg.svg';
import { ReactComponent as TruckIconSVG } from './truck-icon.svg';
import { ReactComponent as CopyinSvg } from './copyin.svg';

export const CopyinIcon: FC<SvgIconProps> = forwardRef((props, ref) => <CopyinSvg ref={ref} {...props} />);
export const FbIcon: FC<SvgIconProps> = forwardRef((props, ref) => <FbIconSvg ref={ref} {...props} />);
export const MultiLinesIcon: FC<SvgIconProps> = forwardRef((props, ref) => <MultiLinesSvg ref={ref} {...props} />);
export const USSLogoIcon: FC<SvgIconProps> = forwardRef((props, ref) => <USSLogoSvg ref={ref} {...props} />);
export const InstagramIcon: FC<SvgIconProps> = forwardRef((props, ref) => <InstagramSvg ref={ref} {...props} />);
export const FacebookIcon: FC<SvgIconProps> = forwardRef((props, ref) => <FacebookSvg ref={ref} {...props} />);
export const LinkedInIcon: FC<SvgIconProps> = forwardRef((props, ref) => <LinkedInSvg ref={ref} {...props} />);
export const RedCirclePointedIcon: FC<SvgIconProps> = forwardRef((props, ref) => <RedCirclePointedSvg ref={ref} {...props} />);
export const MainIcon1: FC<SvgIconProps> = forwardRef((props, ref) => <MainIcon1Svg ref={ref} {...props} />);
export const MainIcon2: FC<SvgIconProps> = forwardRef((props, ref) => <MainIcon2Svg ref={ref} {...props} />);
export const MainIcon3: FC<SvgIconProps> = forwardRef((props, ref) => <MainIcon3Svg ref={ref} {...props} />);
export const MainIcon4: FC<SvgIconProps> = forwardRef((props, ref) => <MainIcon4Svg ref={ref} {...props} />);
export const MainIcon5: FC<SvgIconProps> = forwardRef((props, ref) => <MainIcon5Svg ref={ref} {...props} />);
export const ArrowRight: FC<SvgIconProps> = forwardRef((props, ref) => <ArrowRightSvg ref={ref} {...props} />);
export const VideoIcon: FC<SvgIconProps> = forwardRef((props, ref) => <VideoIconSvg ref={ref} {...props} />);
export const VideoIcon2: FC<SvgIconProps> = forwardRef((props, ref) => <VideoIcon2Svg ref={ref} {...props} />);
export const HandShakeIcon: FC<SvgIconProps> = forwardRef((props, ref) => <HandshakeSvg ref={ref} {...props} />);
export const ImprovementIcon: FC<SvgIconProps> = forwardRef((props, ref) => <ImprovementSvg ref={ref} {...props} />);
export const ListIcon: FC<SvgIconProps> = forwardRef((props, ref) => <ListSvg ref={ref} {...props} />);
export const TeamIcon: FC<SvgIconProps> = forwardRef((props, ref) => <TeamSvg ref={ref} {...props} />);
export const LightModeIcon: FC<SvgIconProps> = forwardRef((props, ref) => <LightModeSvg ref={ref} {...props} />);
export const DarkModeIcon: FC<SvgIconProps> = forwardRef((props, ref) => <DarkModeSvg ref={ref} {...props} />);
export const ArrowIcon: FC<SvgIconProps> = forwardRef((props, ref) => <ArrowSvg ref={ref} {...props} />);
export const IndustriesAirIcon: FC<SvgIconProps> = forwardRef((props, ref) => <IndustriesAirSvg ref={ref} {...props} />);
export const IndustriesLampIcon: FC<SvgIconProps> = forwardRef((props, ref) => <IndustriesLampSvg ref={ref} {...props} />);
export const IndustriesPipeIcon: FC<SvgIconProps> = forwardRef((props, ref) => <IndustriesPipeSvg ref={ref} {...props} />);
export const IndustriesTvIcon: FC<SvgIconProps> = forwardRef((props, ref) => <IndustriesTvSvg ref={ref} {...props} />);
export const ServicesIcon1: FC<SvgIconProps> = forwardRef((props, ref) => <ServicesIcon1Svg ref={ref} {...props} />);
export const ServicesIcon2: FC<SvgIconProps> = forwardRef((props, ref) => <ServicesIcon2Svg ref={ref} {...props} />);
export const ServicesIcon3: FC<SvgIconProps> = forwardRef((props, ref) => <ServicesIcon3Svg ref={ref} {...props} />);
export const ServicesIcon4: FC<SvgIconProps> = forwardRef((props, ref) => <ServicesIcon4Svg ref={ref} {...props} />);
export const ServicesIcon5: FC<SvgIconProps> = forwardRef((props, ref) => <ServicesIcon5Svg ref={ref} {...props} />);
export const ArrowOfferCardIcon: FC<SvgIconProps> = forwardRef((props, ref) => <ArrowOfferCardSvg ref={ref} {...props} />);
export const ErrorPageIcon: FC<SvgIconProps> = forwardRef((props, ref) => <ErrorPageSvg ref={ref} {...props} />);
export const ErrorServerIcon: FC<SvgIconProps> = forwardRef((props, ref) => <ErrorServerSvg ref={ref} {...props} />);
export const PlusIcon: FC<SvgIconProps> = forwardRef((props, ref) => <PlusSvg ref={ref} {...props} />);
export const BespokeServerIcon: FC<SvgIconProps> = forwardRef((props, ref) => <BespokeServerSvg ref={ref} {...props} />);
export const BespokeGearIcon: FC<SvgIconProps> = forwardRef((props, ref) => <BespokeGearSvg ref={ref} {...props} />);
export const BespokeLampIcon: FC<SvgIconProps> = forwardRef((props, ref) => <BespokeLampSvg ref={ref} {...props} />);
export const SearchIcon: FC<SvgIconProps> = forwardRef((props, ref) => <SearchIconSvg ref={ref} {...props} />);
export const WebDesignIcon: FC<SvgIconProps> = forwardRef((props, ref) => <WebDesignIconSvg ref={ref} {...props} />);
export const WebProgrammingIcon: FC<SvgIconProps> = forwardRef((props, ref) => <WebProgrammingIconSvg ref={ref} {...props} />);
export const ChecklistIcon: FC<SvgIconProps> = forwardRef((props, ref) => <ChecklistIconSvg ref={ref} {...props} />);
export const ArrowDownIcon: FC<SvgIconProps> = forwardRef((props, ref) => <ArrowDownSvg ref={ref} {...props} />);
export const ArrowDownWhiteIcon: FC<SvgIconProps> = forwardRef((props, ref) => <ArrowDownWhiteSvg ref={ref} {...props} />);
export const BlackDotsIcon: FC<SvgIconProps> = forwardRef((props, ref) => <BlackDotsSvg ref={ref} {...props} />);
export const WhitePlusIcon: FC<SvgIconProps> = forwardRef((props, ref) => <WhitePlusSvg ref={ref} {...props} />);
export const EmailMapIcon: FC<SvgIconProps> = forwardRef((props, ref) => <EmailMapSvg ref={ref} {...props} />);
export const PhoneMapIcon: FC<SvgIconProps> = forwardRef((props, ref) => <PhoneMapSvg ref={ref} {...props} />);
export const MarkerMapIcon: FC<SvgIconProps> = forwardRef((props, ref) => <MarkerMapSvg ref={ref} {...props} />);
export const CheckCircleIcon: FC<SvgIconProps> = forwardRef((props, ref) => <CheckCircleSvg ref={ref} {...props} />);
export const LockIcon: FC<SvgIconProps> = forwardRef((props, ref) => <LockIconSvg ref={ref} {...props} />);
export const ZeroPercentIcon: FC<SvgIconProps> = forwardRef((props, ref) => <ZeroPercentSvg ref={ref} {...props} />);
export const CircleIcon: FC<SvgIconProps> = forwardRef((props, ref) => <CircleIconSvg ref={ref} {...props} />);
export const USSLogoWhite: FC<SvgIconProps> = forwardRef((props, ref) => <USSLogoWhiteSVG ref={ref} {...props} />);
export const Flower: FC<SvgIconProps> = forwardRef((props, ref) => <FlowerSVG ref={ref} {...props} />);
export const UtilitiesOneLogo: FC<SvgIconProps> = forwardRef((props, ref) => <UtilitiesOneLogoSVG ref={ref} {...props} />);
export const EngineeringIcon: FC<SvgIconProps> = forwardRef((props, ref) => <EngineeringIconSVG ref={ref} {...props} />);
export const AreasWeServeIcon1: FC<SvgIconProps> = forwardRef((props, ref) => <AreasWeServeIcon1SVG ref={ref} {...props} />);
export const AreasWeServeIcon2: FC<SvgIconProps> = forwardRef((props, ref) => <AreasWeServeIcon2SVG ref={ref} {...props} />);
export const AreasWeServeIcon3: FC<SvgIconProps> = forwardRef((props, ref) => <AreasWeServeIcon3SVG ref={ref} {...props} />);
export const AreasWeServeIcon4: FC<SvgIconProps> = forwardRef((props, ref) => <AreasWeServeIcon4SVG ref={ref} {...props} />);
export const AreasWeServeIcon5: FC<SvgIconProps> = forwardRef((props, ref) => <AreasWeServeIcon5SVG ref={ref} {...props} />);
export const EnergyIcon: FC<SvgIconProps> = forwardRef((props, ref) => <EnergyIconSvg ref={ref} {...props} />);
export const ConstructionIcon: FC<SvgIconProps> = forwardRef((props, ref) => <ConstructionIconSVG ref={ref} {...props} />);
export const PatronTruckingLogo: FC<SvgIconProps> = forwardRef((props, ref) => <PatronTruckingLogoSVG ref={ref} {...props} />);
export const ServerIcon: FC<SvgIconProps> = forwardRef((props, ref) => <ServerIconSvg ref={ref} {...props} />);
export const TruckloadLogo: FC<SvgIconProps> = forwardRef((props, ref) => <TruckloadSVG ref={ref} {...props} />);
export const LessThanTruckloadLogo: FC<SvgIconProps> = forwardRef((props, ref) => <LessThanTruckloadSVG ref={ref} {...props} />);
export const IntermodalLogo: FC<SvgIconProps> = forwardRef((props, ref) => <IntermodalSVG ref={ref} {...props} />);
export const MultiDotsIcon: FC<SvgIconProps> = forwardRef((props, ref) => <MultiDotsSVG ref={ref} {...props} />);
export const SettingsIcon: FC<SvgIconProps> = forwardRef((props, ref) => <SettingsSVG ref={ref} {...props} />);
export const EngineeringBgIcon: FC<SvgIconProps> = forwardRef((props, ref) => <EngineeringBgIconSVG ref={ref} {...props} />);
export const TruckIcon: FC<SvgIconProps> = forwardRef((props, ref) => <TruckIconSVG ref={ref} {...props} />);
