import { FC, useState } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

interface CounterUp {
  end: number;
  suffix?: string;
  prefix?: string;
  separator?: string;
  className?: string;
}

const CounterUp: FC<CounterUp> = (props) => {
  const { end, suffix, prefix, separator, className } = props;
  const [viewPortEntered, setViewPortEntered] = useState(false);

  return (
    <CountUp start={viewPortEntered ? null : 0} end={end} suffix={suffix} prefix={prefix} separator={separator} redraw>
      {({ countUpRef }) => (
        <VisibilitySensor
          active={!viewPortEntered}
          onChange={(isVisible) => {
            if (isVisible) {
              setViewPortEntered(true);
            }
          }}
          delayedCall
        >
          <span className={className} ref={countUpRef} />
        </VisibilitySensor>
      )}
    </CountUp>
  );
};

export default CounterUp;
