import { FC, ReactNode } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Theme, Typography } from '@material-ui/core';

interface BasicCardProps {
  title: string;
  text: string;
  icon: ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    backgroundColor: theme.palette.switchedColors.prompter,
    boxShadow: 'none',
    borderRadius: 15,
    '&:hover': {
      background: theme.palette.switchedColors.prompter,
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(9, 3, 3),
    },
  },
  title: {
    marginTop: theme.spacing(5),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(8),
    },
  },
  text: {
    marginTop: theme.spacing(3),
    color: theme.palette.text.secondary,
    whiteSpace: 'pre-line',
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(4),
    },
  },
  icon: {
    '& svg path': {
      fill: theme.palette.switchedColors.main,
    },
  },
}));

const BasicCard: FC<BasicCardProps> = (props) => {
  const { title, text, icon } = props;
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent>
        <div className={classes.icon}>{icon}</div>
        <Typography variant="h4" className={classes.title}>
          {title}
        </Typography>
        <Typography variant="body2" className={classes.text} component="h4">
          {text}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default BasicCard;
