import { alpha, Box, Button, Collapse, Container, Grid, Hidden, makeStyles, Theme, Typography } from '@material-ui/core';
import { GridSize } from '@material-ui/core/Grid/Grid';
import clsx from 'clsx';
import { ContentDescription } from 'components/structures/general';
import { useDarkMode, useMediaUp } from 'hooks';
import { FC, ReactNode, useState } from 'react';
import { ArrowIcon, RedCirclePointedIcon } from 'svgs';

export interface ImageDescriptionProps {
  image: {
    image: string;
    srcSet?: string;
    imageWrapperClass?: string;
    imageClass?: string;
    disableShadow?: boolean;
    disableMaxWidthLg?: boolean;
    betweenSpaceLg?: boolean;
    imageElements?: ReactNode;
    imageCenterLg?: boolean;
    borderRadius?: number;
  };
  gridItems?: { imageMd: GridSize; imageLg: GridSize; contentMd: GridSize; contentLg: GridSize };
  fullImage?: boolean;
  upperTitle?: string;
  title: string | ReactNode;
  align?: 'left' | 'right';
  bgImage?: { image: string; darkImage?: string; position?: 'right'; hideMdBg?: boolean };
  bgColor?: string;
  text?: string | ReactNode;
  onlyWhiteText?: boolean;
  showRedCircleIcon?: boolean;
  advanceText?: { text: string; buttonOpen: string; buttonClose: string };
  link?: { text: string; href: string };
  className?: string;
  contentClassName?: string;
}

enum Position {
  left = 'row',
  right = 'row-reverse',
}

interface StyleProps extends Partial<ImageDescriptionProps> {
  isDarkMode: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: ({ bgColor }: StyleProps) => bgColor,
    backgroundImage: 'none',
  },
  rootImage: {
    position: 'relative',
    margin: ({ fullImage }: StyleProps) => fullImage && theme.spacing(10, 0),
    [theme.breakpoints.up('sm')]: {
      margin: ({ fullImage }: StyleProps) => fullImage && theme.spacing(15, 0),
    },
    [theme.breakpoints.up('md')]: {
      backgroundImage: ({ bgImage, isDarkMode }: StyleProps) => `url(${(isDarkMode && bgImage?.darkImage) || bgImage?.image})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: ({ bgImage }: StyleProps) => `${bgImage?.position && '115% center'}`,
    },
    [theme.breakpoints.down('md')]: {
      backgroundImage: ({ bgImage }: StyleProps) => `${bgImage?.hideMdBg && 'none !important'}`,
    },
    [theme.breakpoints.up('lg')]: {
      backgroundPosition: ({ bgImage }: StyleProps) => `${bgImage?.position || 'center'}`,
    },
  },
  contentPadding: {
    padding: theme.spacing(10, 0),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(15, 0),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(13.5, 0),
    },
  },
  content: {
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: ({ align }: StyleProps) => Position[align],
    },
    [theme.breakpoints.up('lg')]: {
      alignItems: ({ image: { imageCenterLg } }: StyleProps) => imageCenterLg && 'center',
    },
  },
  image: {
    width: '100%',
    borderRadius: ({ image }: StyleProps) => (typeof image.borderRadius === 'undefined' ? 15 : image.borderRadius),
    maxWidth: 350,
    margin: theme.spacing(0, 'auto', 16),
    display: 'block',
    boxShadow: ({ image }: StyleProps) =>
      `${image.disableShadow ? `none` : `0px 4px 23px ${alpha(theme.palette.common.black, 0.15)}`}`,
    maxHeight: 350,
    objectFit: 'cover',
    [theme.breakpoints.up('sm')]: {
      maxHeight: 'inherit',
      objectFit: 'inherit',
    },
    [theme.breakpoints.up('md')]: {
      position: 'sticky',
      top: '20%',
      display: 'inline',
      maxWidth: ({ image }: StyleProps) => (image.disableMaxWidthLg ? 'initial' : 516),
      marginBottom: 0,
    },
    [theme.breakpoints.up('lg')]: {
      position: ({ image: { imageCenterLg } }: StyleProps) => (imageCenterLg ? 'static' : 'sticky'),
    },
  },
  contentWrapper: {
    padding: theme.spacing(10, 0),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(14, 0),
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: `${theme.spacing(10)}px !important`,
      paddingBottom: `${theme.spacing(10)}px !important`,
    },
  },
  fullImageWrapper: {
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
    [theme.breakpoints.only('md')]: {
      padding: '0 !important',
      position: 'absolute',
      left: ({ align }: StyleProps) => (align === 'right' ? 'inherit' : 0),
      right: ({ align }: StyleProps) => (align === 'right' ? 0 : 'inherit'),
      maxHeight: '100%',
      height: '100%',
      width: '100%',
      top: 0,
      overflow: 'hidden',
    },
  },
  fullImage: {
    width: '100%',
    display: 'block',
    height: 'max-content',
    objectFit: 'cover',
    minHeight: 365,
    maxHeight: 400,
    objectPosition: 'center',
    [theme.breakpoints.up('sm')]: {
      objectPosition: 'center 24%',
      maxHeight: 500,
    },
    [theme.breakpoints.up('md')]: {
      height: '100%',
      objectPosition: 'center',
      maxHeight: 'inherit',
    },
  },
  text: {
    '&:not(:last-child)': {
      marginTop: theme.spacing(3),
    },
    whiteSpace: 'pre-line',
  },
  colorText: {
    color: theme.palette.common.white,
  },
  button: {
    backgroundColor: ({ bgColor }: StyleProps) => bgColor && theme.palette.primary.light,
    backgroundImage: ({ bgColor }: StyleProps) => bgColor && 'none',
    boxShadow: ({ bgColor }: StyleProps) => bgColor && 'none',
    marginTop: theme.spacing(6),
    '&:hover': {
      boxShadow: ({ bgColor }: StyleProps) => bgColor && 'none',
      backgroundColor: ({ bgColor }: StyleProps) => bgColor && theme.palette.primary.light,
      backgroundImage: ({ bgColor }: StyleProps) => bgColor && 'none',
    },
  },
  arrow: {
    marginLeft: 10,
    transition: 'transform .7s',
  },
  arrowActive: {
    transform: 'rotateZ(180deg)',
  },
  unsetStyle: {
    all: 'unset',
  },
  unsetStyleSmDown: {
    [theme.breakpoints.down('sm')]: {
      all: 'unset',
    },
  },
  unsetStyleMdUp: {
    [theme.breakpoints.up('md')]: {
      all: 'unset',
    },
  },
  redCirclePosition: {
    position: 'absolute',
    bottom: ({ align }: StyleProps) => (align === 'right' ? 0 : 30),
    right: ({ align }: StyleProps) => (align === 'right' ? '36%' : 20),
    [theme.breakpoints.up('lg')]: {
      bottom: ({ align }: StyleProps) => (align === 'right' ? 0 : 40),
      right: ({ align }: StyleProps) => (align === 'right' ? '-30%' : -15),
    },
  },
}));

const ImageDescription: FC<ImageDescriptionProps> = (props) => {
  const {
    image,
    bgImage,
    align = 'left',
    link,
    title,
    text,
    upperTitle,
    advanceText,
    bgColor,
    fullImage,
    showRedCircleIcon,
    children,
    onlyWhiteText,
    className,
    contentClassName,
    gridItems = { contentLg: 6, contentMd: 7, imageMd: 5, imageLg: 5 },
  } = props;
  const { isDarkMode } = useDarkMode();
  const classes = useStyles({ bgImage, align, bgColor, fullImage, image, isDarkMode });
  const isMediumUp = useMediaUp('md');
  const isLargeUp = useMediaUp('lg');

  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Box className={clsx(classes.root, bgImage && classes.rootImage, className)}>
      <Container maxWidth={false} fixed className={clsx(fullImage && classes.unsetStyleSmDown)}>
        <Grid
          className={clsx(classes.content, !fullImage && classes.contentPadding)}
          justifyContent="space-between"
          container
          spacing={isMediumUp ? 6 : 0}
        >
          {image.betweenSpaceLg && (
            <Hidden mdDown>
              <Grid lg={1} item />
            </Hidden>
          )}
          <Grid
            xs={12}
            md={gridItems.imageMd}
            lg={gridItems.imageLg}
            item
            className={clsx(fullImage && classes.fullImageWrapper, image.imageWrapperClass)}
          >
            <>
              <img
                className={clsx(fullImage ? classes.fullImage : classes.image, image.imageClass)}
                alt={image.image}
                srcSet={image.srcSet && image.srcSet}
                src={image.image}
              />
              {image.imageElements && image.imageElements}
            </>
          </Grid>
          {image.betweenSpaceLg && (
            <Hidden mdDown>
              <Grid lg={1} item />
            </Hidden>
          )}
          {fullImage && (
            <Hidden smDown lgUp>
              <Grid xs={12} md={5} lg={5} item />
            </Hidden>
          )}
          <Grid
            xs={12}
            md={gridItems.contentMd}
            lg={gridItems.contentLg}
            item
            className={fullImage && classes.contentWrapper}
            style={{ position: isLargeUp ? 'relative' : 'static' }}
          >
            <Container maxWidth={false} fixed className={clsx(fullImage ? classes.unsetStyleMdUp : classes.unsetStyle)}>
              <Box position="relative" zIndex={1} className={contentClassName}>
                <ContentDescription
                  text={{ text: text, className: onlyWhiteText && classes.colorText }}
                  title={{ text: title, className: onlyWhiteText && classes.colorText }}
                  link={link && { ...link, className: classes.button }}
                  upperTitle={
                    upperTitle && {
                      text: upperTitle,
                      upperTitleTextClass: onlyWhiteText && classes.colorText,
                      colorSquare: onlyWhiteText && '#55aa88',
                    }
                  }
                />
              </Box>
              {showRedCircleIcon && (
                <Hidden smDown>
                  <RedCirclePointedIcon className={classes.redCirclePosition} />
                </Hidden>
              )}
              {advanceText && (
                <>
                  <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <Typography variant="body1" className={classes.text}>
                      {advanceText.text}
                    </Typography>
                  </Collapse>
                  <Button
                    variant="contained"
                    className={classes.button}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                    color="primary"
                  >
                    {expanded ? advanceText.buttonClose : advanceText.buttonOpen}
                    <ArrowIcon className={clsx(classes.arrow, expanded && classes.arrowActive)} />
                  </Button>
                </>
              )}
              {children}
            </Container>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ImageDescription;
