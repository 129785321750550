import { alpha, Box, Container, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { FC } from 'react';
import { MultiLinesIcon } from 'svgs';

interface TitleWithImageProps {
  image: string;
  title: string;
  bgImage?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundImage: 'none',
    [theme.breakpoints.up('md')]: {
      backgroundImage: ({ bgImage }: Partial<TitleWithImageProps>) => `url(${bgImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      marginTop: theme.spacing(11),
    },
  },
  content: {
    display: 'flex',
    padding: theme.spacing(10, 0),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(15, 0),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(13.5, 0),
    },
  },
  title: {
    textAlign: 'center',
    marginBottom: 0,
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(3),
    },
  },
  iconBlock: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
  imageBlock: {
    marginTop: theme.spacing(9),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(21),
    },
    '& img': {
      borderRadius: 20,
      boxShadow: `0px 4px 23px ${alpha(theme.palette.common.black, 0.25)}`,
    },
  },
  icon: {
    height: 16,
    maxWidth: 82,
    [theme.breakpoints.up('sm')]: {
      height: 25,
      maxWidth: 143,
    },
  },
}));

const TitleWithImage: FC<TitleWithImageProps> = (props) => {
  const { image, bgImage, title } = props;
  const classes = useStyles({ bgImage });

  return (
    <Box className={bgImage && classes.root}>
      <Container maxWidth={false} fixed>
        <Grid className={classes.content} justifyContent="center" container>
          <Grid item>
            <Typography variant="h1" className={classes.title}>
              {title}
            </Typography>
          </Grid>
          <Grid xs={12} className={classes.iconBlock} item>
            <MultiLinesIcon className={classes.icon} width="100%" height="100%" viewBox="0 0 59 11" />
          </Grid>
          <Grid xs={12} lg={10} className={classes.imageBlock} item>
            <img src={image} alt={image} width="100%" />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default TitleWithImage;
