import React, { FC } from 'react';
import { Box, Container, Grid, makeStyles, Theme } from '@material-ui/core';
import { FullSizeContentDescription } from 'components/structures/general';
import clsx from 'clsx';

interface FrameSectionProps {
  title: string;
  text: string;
  withoutMultiLinesIcon?: boolean;
  innerContainerClassName?: string;
  contentClassName?: string;
  link: { text: string; href: string };
}

const useStyles = makeStyles((theme: Theme) => ({
  position: {
    position: 'relative',
  },
  line: {
    position: 'absolute',
    width: 30,
    height: 80,
    [theme.breakpoints.up('sm')]: {
      width: 50,
    },
    [theme.breakpoints.up('md')]: {
      width: 100,
      height: 60,
    },
  },
  lineTopLeft: {
    top: 0,
    left: 0,
    borderTop: `4px solid ${theme.palette.primary.main}`,
    borderLeft: `4px solid ${theme.palette.primary.main}`,
  },
  lineBottomLeft: {
    left: 0,
    bottom: 0,
    borderBottomLeftRadius: 20,
    borderLeft: `4px solid ${theme.palette.primary.main}`,
    borderBottom: `4px solid ${theme.palette.primary.main}`,
  },
  lineTopRight: {
    top: 0,
    right: 0,
    borderTopRightRadius: 20,
    borderTop: `4px solid ${theme.palette.primary.main}`,
    borderRight: `4px solid ${theme.palette.primary.main}`,
  },
  lineBottomRight: {
    right: 0,
    bottom: 0,
    borderRight: `4px solid ${theme.palette.primary.main}`,
    borderBottom: `4px solid ${theme.palette.primary.main}`,
  },
  bgWhite: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(5, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(10),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(20),
    },
  },
  title: {
    color: theme.palette.switchedColors.frameTitle,
  },
  textMargin: {
    marginTop: theme.spacing(6),
  },
}));

const FrameSection: FC<FrameSectionProps> = ({
  title,
  text,
  withoutMultiLinesIcon = false,
  innerContainerClassName = '',
  contentClassName = '',
  link,
}) => {
  const classes = useStyles();

  return (
    <Container maxWidth={false} fixed>
      <Grid container justifyContent="center">
        <Grid item lg={10} xs={12}>
          <FullSizeContentDescription
            title={{ text: title, variant: 'h2', className: classes.title }}
            description={{ text, className: classes.textMargin }}
            className={clsx(classes.bgWhite, contentClassName)}
            withoutMultiLinesIcon={withoutMultiLinesIcon}
            innerContainerClassName={innerContainerClassName}
            customIcons={
              <>
                <Box className={clsx(classes.line, classes.lineTopLeft)} />
                <Box className={clsx(classes.line, classes.lineBottomLeft)} />
                <Box className={clsx(classes.line, classes.lineTopRight)} />
                <Box className={clsx(classes.line, classes.lineBottomRight)} />
              </>
            }
            link={link}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default FrameSection;
