import React, { FC, ReactNode } from 'react';
import { Box, Container, Grid, makeStyles, Theme } from '@material-ui/core';
import { GridSize } from '@material-ui/core/Grid/Grid';
import { useMediaUp } from 'hooks';
import { ContentDescription } from 'components/structures/general';
import clsx from 'clsx';

export interface ImageContentProps {
  imageMain: {
    image: string;
    srcSet?: string;
  };
  imageGridLg?: GridSize;
  contentGridLg?: GridSize;
  content: {
    svgIconHeader?: ReactNode;
    title: string;
    image?: string;
    text: string;
    link?: { text: string; href: string; className?: string };
  };
  className?: string;
  align?: 'left' | 'right';
}

enum Position {
  left = 'row',
  right = 'row-reverse',
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(10, 0),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(15, 0),
    },
  },
  iconSvg: {
    '& svg': {
      display: 'block',
      maxHeight: 53,
      maxWidth: 53,
    },
    marginBottom: theme.spacing(4),
    '& circle': {
      fill: theme.palette.switchedColors.main,
    },
    '& path': {
      fill: theme.palette.switchedColors.main,
    },
    [theme.breakpoints.up('sm')]: {
      '& svg': {
        maxHeight: 79,
        maxWidth: 79,
      },
    },
  },
  content: {
    flexDirection: 'column-reverse',
    [theme.breakpoints.up('md')]: {
      flexDirection: ({ align }: Partial<ImageContentProps>) => Position[align],
    },
    [theme.breakpoints.up('lg')]: {
      alignItems: 'center',
    },
  },
  image: {
    width: '100%',
    borderRadius: theme.spacing(3),
    maxWidth: 345,
    margin: theme.spacing(10, 'auto', 0),
    display: 'block',
    objectFit: 'cover',
    [theme.breakpoints.up('md')]: {
      maxWidth: '100%',
      marginTop: 0,
    },
  },
  title: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 64,
    },
  },
  imageContent: {
    width: '100%',
    borderRadius: theme.spacing(3),
    display: 'block',
    maxWidth: 345,
    maxHeight: 416,
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(6, 0, 5),
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 380,
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '100%',
    },
  },
  text: {
    color: theme.palette.switchedColors.textGray,
  },
}));

const ImageContent: FC<ImageContentProps> = (props) => {
  const { align = 'left', children, className, contentGridLg = 6, content, imageMain, imageGridLg = 6 } = props;
  const classes = useStyles({ align });
  const isMediumUp = useMediaUp('md');

  return (
    <Box className={clsx(classes.root, className)}>
      <Container maxWidth={false} fixed>
        <Grid
          className={classes.content}
          justifyContent="space-between"
          alignItems="center"
          container
          spacing={isMediumUp ? 6 : 0}
        >
          <Grid xs={12} md={6} lg={imageGridLg} item>
            <img
              className={classes.image}
              alt={imageMain.image}
              srcSet={imageMain.srcSet && imageMain.srcSet}
              src={imageMain.image}
            />
          </Grid>
          <Grid xs={12} md={6} lg={contentGridLg} item>
            {content.svgIconHeader && <Box className={classes.iconSvg}>{content.svgIconHeader}</Box>}
            <ContentDescription
              title={{ text: content.title, className: classes.title }}
              text={{ text: content.text, variant: 'body2', className: classes.text }}
              link={content.link}
            >
              {content.image && <img src={content.image} alt={content.image} className={classes.imageContent} />}
            </ContentDescription>
            {children}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ImageContent;
