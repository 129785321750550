import { Box, Card, Grid, GridSize, Link, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { FC } from 'react';
import clsx from 'clsx';
import { useMediaUp } from 'hooks';
import { Post } from 'services/blog/types';
import { hex2rgba } from 'utils/colorHelpers';
import { displayPublishAt, truncate } from 'utils/date';

interface PostCardProps {
  post: Post;
  md?: GridSize;
  md2?: GridSize;
  lg?: GridSize;
  firstCard?: boolean;
  index?: number;
  truncateCount?: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  rootCard: {
    background: theme.palette.switchedColors.simpleCard,
    color: theme.palette.text.primary,
    boxShadow: 'none',
    padding: theme.spacing(0, 1, 6),
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '&:hover': {
      background: theme.palette.switchedColors.simpleCard,
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: 'auto',
      maxWidth: '100%',
      padding: theme.spacing(0, 1, 2),
    },
    [theme.breakpoints.up('lg')]: {
      minWidth: 407,
    },
  },
  href: {
    color: 'inherit',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  imageBlock: {
    width: '100%',
  },
  image: {
    minHeight: 313,
    borderRadius: theme.spacing(4),
    width: '100%',
    objectFit: 'cover',
    [theme.breakpoints.up('sm')]: {
      minHeight: 370,
      maxHeight: ({ firstCard }: Partial<PostCardProps>) => (firstCard ? 'auto' : 370),
    },
    [theme.breakpoints.up('md')]: {
      minHeight: ({ firstCard }: Partial<PostCardProps>) => (firstCard ? 445 : 370),
    },
  },
  tag: {
    backgroundColor: ({ color }: any) => `${hex2rgba(color, 0.2)}`,
    color: ({ color }: any) => `${color}`,
    width: 'fit-content',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1, 6),
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(-3),
    [theme.breakpoints.up('md')]: {
      marginTop: ({ firstCard }: Partial<PostCardProps>) => theme.spacing(firstCard ? 0 : -3),
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: ({ firstCard }: Partial<PostCardProps>) => theme.spacing(firstCard ? 0 : -2),
    },
  },
  description: {
    maxWidth: 690,
  },
  container: {
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(8),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(12),
    },
    [theme.breakpoints.up('md')]: {
      marginTop: ({ firstCard }: Partial<PostCardProps>) => theme.spacing(firstCard ? 4 : 7),
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: ({ firstCard }: Partial<PostCardProps>) => theme.spacing(firstCard ? 10 : 7),
      marginBottom: theme.spacing(16),
    },
  },
  userPhoto: {
    maxWidth: 77,
    maxHeight: 77,
    borderRadius: '50%',
    marginRight: theme.spacing(2),
  },
  text: {
    marginTop: theme.spacing(1),
  },
  data: {
    fontSize: 14,
  },
  span: {
    marginLeft: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(1),
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(6),
    },
  },
  spacing: {
    paddingBottom: 0,
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.spacing(5),
    },
    [theme.breakpoints.up('lg')]: {
      paddingBottom: 0,
    },
  },
  iconBlock: {
    display: 'grid',
    marginBottom: 0,
    gridTemplateColumns: '0.1fr 1fr',
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(8),
    },
  },
  inlineGrid: {
    gridTemplateColumns: '1.3fr 1fr 1fr 1fr',
  },
}));

const PostCard: FC<PostCardProps> = (props) => {
  const { post, firstCard, md, md2, lg, index = 0, truncateCount = 200 } = props;
  const classes = useStyles({ firstCard, color: post.tags[0]?.color || '' });
  const isLargeUp = useMediaUp('lg');
  const href = `/blog/${post.id}`;

  return (
    <Card className={classes.rootCard} id={`postCard${index}`}>
      <Grid container spacing={5} className={classes.spacing}>
        <Grid item md={md} sm={12} className={classes.imageBlock}>
          <Link href={href} className={classes.href}>
            <img src={post.image} alt={'photoBlog'} className={classes.image} loading="lazy" />
          </Link>
        </Grid>
        <Grid item md={md2} sm={12}>
          <Link href={href} className={classes.href}>
            <Typography variant="body2" className={classes.tag}>
              {post.tags.length ? post.tags[0].title : ''}
            </Typography>
            <Typography variant="h3">{post.title}</Typography>
            <Box mt={3} />
            <Typography variant="body2" className={classes.description}>
              {truncate(post.description, truncateCount)}
            </Typography>
          </Link>
          {firstCard && (
            <Grid container justifyContent="flex-start" className={classes.container}>
              <Grid item>
                <Link href={href} className={classes.href}>
                  <img src={post.author.avatar} alt={'photoBlog'} className={classes.userPhoto} loading="lazy" />
                </Link>
              </Grid>
              <Grid item>
                <Link href={href} className={classes.href}>
                  <Typography variant="h4">
                    {post.author.first_name} {post.author.last_name}
                  </Typography>
                  <Typography variant="body2" className={classes.text}>
                    {post.author.title}
                  </Typography>
                  <Typography className={classes.data}>
                    {displayPublishAt(post.publish_at)}
                    <span className={classes.span}>Time to read - {post.time_to_read} min</span>
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      {firstCard ? (
        <div style={{ display: 'none' }} />
      ) : (
        <Grid container>
          <Grid item md={md2} sm={12}>
            <Box mt={6}>
              <Grid
                className={clsx(classes.iconBlock, isLargeUp && (lg === 8 || lg === 12) && classes.inlineGrid)}
                justifyContent="flex-start"
                container
              >
                <Grid item>
                  <Link href={href} className={classes.href}>
                    <img src={post.author.avatar} alt={'photoBlog'} className={classes.userPhoto} loading="lazy" />
                  </Link>
                </Grid>
                <Grid item>
                  <Link href={href} className={classes.href}>
                    <Typography variant="h4">
                      {post.author.first_name} {post.author.last_name}
                    </Typography>
                    <Typography variant="body2" className={classes.text}>
                      {post.author.title}
                    </Typography>
                    <Typography className={classes.data}>
                      {displayPublishAt(post.publish_at)}
                      <span className={classes.span}>Time to read - {post.time_to_read} min</span>
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      )}
    </Card>
  );
};

export default PostCard;
