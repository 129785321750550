import axios from 'axios';
import { FormApi, SubmissionErrors } from 'final-form';
import { useState } from 'react';
import { toast } from 'react-toastify';

export interface IFormSenderHook {
  onSubmit: TOnSubmit;
  status: TStatus;
}

export type TSimpleObject = {
  [key: string]: string;
};

export type TStatus = {
  loading: boolean;
  error: boolean;
  success: boolean;
};

export type TOnSubmit = (
  values: TSimpleObject,
  actions: FormApi<TSimpleObject, Partial<TSimpleObject>>,
  callback?: (errors?: SubmissionErrors) => void,
  externalValues?: TSimpleObject,
) => Promise<void>;

const useFormSender = (route: string): IFormSenderHook => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const status: TStatus = { loading, error, success };

  const onSubmit: TOnSubmit = async (values, actions, cb, externalValues = {}): Promise<void> => {
    const resetEachField = () => Object.keys(values).map((key) => actions.resetFieldState(key));
    if (!success) {
      try {
        setLoading(true);
        await axios.post(route, { ...values, ...externalValues });
        setSuccess(true);
        actions.reset();
        resetEachField();
        toast.success('Thank you for filling out your information !', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }
  };

  return { status, onSubmit };
};

export default useFormSender;
