import { FC } from 'react';
import { Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { MultiLinesIcon } from 'svgs';
import { createStyles, makeStyles } from '@material-ui/styles';
import { SquareIconComponent } from 'components/atoms';
import { SquareIconComponentProps } from 'components/atoms/SquareIconComponent';

export interface UpperTitleProps extends SquareIconComponentProps {
  className?: string;
  upperTitleTextClass?: string;
  text: string;
}

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    upperTitleWrapper: {
      display: 'flex',
      alignItems: 'flex-end',
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing(1),
      },
    },
    upperTitle: {
      width: '100%',
      fontWeight: 400,
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(-9),
      '& svg': {
        marginLeft: theme.spacing(2),
      },
      [theme.breakpoints.up(375)]: {
        '& svg': {
          marginLeft: theme.spacing(4),
        },
        marginLeft: theme.spacing(-5),
      },
      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing(4),
        marginLeft: theme.spacing(-8),
      },
    },
    colorSvg: {
      '& path': {
        stroke: theme.palette.primary.light,
      },
    },
  });
});

const UpperTitle: FC<UpperTitleProps> = ({ className, text, upperTitleTextClass, colorSquare }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.upperTitleWrapper, className)}>
      <SquareIconComponent colorSquare={colorSquare} />
      <Typography variant="h4" className={clsx(classes.upperTitle, upperTitleTextClass)} color="textSecondary">
        {text}
        <MultiLinesIcon className={clsx(upperTitleTextClass && classes.colorSvg)} />
      </Typography>
    </div>
  );
};
export default UpperTitle;
