import { Ref, useEffect, useRef } from 'react';
import { useInViewport } from 'react-in-viewport';

export type TUseViewportProps = {
  setInViewPort: (index: number) => void;
  index: number;
  viewportParams?: IntersectionObserverInit;
};

const useIsViewport = ({
  setInViewPort,
  index,
  viewportParams = { rootMargin: '-300px' },
}: TUseViewportProps): { ref: Ref<HTMLDivElement> } => {
  const ref = useRef(null);
  const { inViewport } = useInViewport(ref, viewportParams, { disconnectOnLeave: false }, {});

  useEffect(() => {
    if (inViewport) setInViewPort(index);
  }, [inViewport]);

  return { ref };
};

export default useIsViewport;
