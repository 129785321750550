import { Box, BoxProps, makeStyles, Theme, Typography } from '@material-ui/core';
import { useParallax } from 'hooks';
import { FC } from 'react';
import { animated } from 'react-spring';
import { responsiveFontSize } from 'utils/typographyHelpers';

interface PrompterTitleProps extends BoxProps {
  title: string;
  transformStrength?: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    fontSize: responsiveFontSize(64, 144),
    color: theme.palette.switchedColors.prompter,
    clear: 'both',
    display: 'inline-block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    userSelect: 'none',
  },
}));

const PrompterTitle: FC<PrompterTitleProps> = ({ title, transformStrength = 1, ...boxProps }) => {
  const classes = useStyles();
  const { ParallaxProvider, transform } = useParallax('X');
  const style = { transform: transform(transformStrength) };

  return (
    <ParallaxProvider>
      <Box overflow="hidden" {...boxProps}>
        <animated.div style={style}>
          <Typography className={classes.root} variant="h1">
            {title}
          </Typography>
        </animated.div>
      </Box>
    </ParallaxProvider>
  );
};

export default PrompterTitle;
