import React, { FC } from 'react';
import { Box, makeStyles, Theme } from '@material-ui/core';

interface CardWithIconProps {
  icon: string;
  text: string;
  heightMdUp?: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: 381,
    background: theme.palette.switchedColors.prompter,
    borderRadius: theme.spacing(4),
    padding: theme.spacing(6),
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      minWidth: 298,
      minHeight: ({ heightMdUp }: CardWithIconProps) => heightMdUp,
    },
  },
  image: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(8),
    display: 'block',
  },
}));

const CardWithIcon: FC<CardWithIconProps> = ({ icon, text, heightMdUp }) => {
  const classes = useStyles({ heightMdUp, icon, text });

  return (
    <Box className={classes.root}>
      <img src={icon} alt={'icon'} className={classes.image} />
      {text}
    </Box>
  );
};

export default CardWithIcon;
