import { alpha, Box, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { ImageDescription } from 'components/structures/general';
import { ImageDescriptionProps } from 'components/structures/general/ImageDescription';
import { FC, ReactNode } from 'react';

interface ListImageDescriptionProps {
  listTitle?: ReactNode | string;
  list?: Array<{ iconSvg?: ReactNode; shortedText?: string; text: string }>;
  imageDescriptionProps: ImageDescriptionProps;
}

type StyleProps = Partial<ListImageDescriptionProps>;

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    marginTop: theme.spacing(6),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(10),
    },
  },
  gridWrapper: {
    marginTop: ({ listTitle }: Partial<StyleProps>) => theme.spacing(listTitle ? 4 : 6),
  },
  gridItem: {
    '&:not(:nth-last-child(-n+2))': {
      marginBottom: theme.spacing(6),
    },
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    display: 'flex',
    width: '100%',
    maxWidth: 32,
    marginRight: 10,
    [theme.breakpoints.up('sm')]: {
      maxWidth: 39,
    },
    '& svg path': {
      fill: theme.palette.switchedColors.main,
    },
  },
  shortedText: {
    fontSize: 18,
    fontWeight: 700,
    color: theme.palette.switchedColors.main,
    backgroundColor: alpha(theme.palette.switchedColors.main, 0.2),
    padding: theme.spacing(1.4, 3),
    borderRadius: theme.spacing(0, 1),
    marginRight: theme.spacing(2),
  },
  text: {
    wordSpacing: '100vw',
  },
}));

const ListImageDescription: FC<ListImageDescriptionProps> = ({ imageDescriptionProps, listTitle, list }) => {
  const classes = useStyles({ listTitle });

  return (
    <ImageDescription {...imageDescriptionProps}>
      {listTitle && (
        <Typography variant="h5" className={classes.title}>
          {listTitle}
        </Typography>
      )}
      <Grid container className={classes.gridWrapper}>
        {list?.map(({ iconSvg, shortedText, text }, index) => (
          <Grid item xs={6} sm={6} key={index} className={classes.gridItem}>
            <Box className={classes.listItem}>
              {iconSvg && <Box className={classes.icon}>{iconSvg}</Box>}
              {shortedText && <Typography className={classes.shortedText}>{shortedText}</Typography>}
              <Typography className={classes.text}>{text}</Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </ImageDescription>
  );
};

export default ListImageDescription;
